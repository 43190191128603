import $ from 'jquery';
import 'what-input';

// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';

import {
    Swiper,
    Navigation,
    Pagination,
    Scrollbar,
    Autoplay,
    EffectFade,
    A11y
} from 'swiper';
Swiper.use([Navigation, Pagination, Scrollbar, Autoplay, EffectFade, A11y]);
//import Instafeed from 'instafeed.js';
require('@fancyapps/fancybox');

$(document).foundation();

/* var feed = new Instafeed({
   accessToken:'IGQVJYeEo1ZA2xyYTBSX3R1ZAXRsV2h5ZAmgyUm42bi13WGFUc09SdXY2amNkMnNvWVRDVGxpdUNIR0dIQTJEc0hzM1NRWEpQWFlYRkRUMTJ6dmhPMGJwNkI0LU03b2I5bndZAbmJrSW42VVN5VkFhWkFKYwZDZD'
 });
 feed.run();*/

const swiperEpisodes = new Swiper('.swiper--episodes', {
    slidesPerView: 'auto',
    centeredSlides: false,
    navigation: {
        nextEl: '.swiper-controls--episodes .swiper-button--next',
        prevEl: '.swiper-controls--episodes .swiper-button--prev',
    }
});

const swiperInstagram = new Swiper('.swiper--instagram', {
    slidesPerView: 'auto',
    centeredSlides: false,
    navigation: {
        nextEl: '.swiper-controls--instagram .swiper-button--next',
        prevEl: '.swiper-controls--instagram .swiper-button--prev',
    }
});


var $contactForm = $(".contact-form");
$contactForm.on("submit", function (e) {
    e.preventDefault();

    var $form = $(this);
    var $formMessages = $form.find(".contact-form__response");
    var $button = $form.find("button");

    $button.attr("disabled", true).addClass("is-loading");
    $formMessages.html("").hide();

    $.ajax({
            type: "post",
            url: $form.attr("action"),
            data: $form.serialize()
        })
        .done(function (data) {
            $button.attr("disabled", false).removeClass("is-loading");
            $formMessages.removeClass("alert").addClass("success");
            $form[0].reset();

            // Set the message text.
            if (data && data !== "") {
                $formMessages.html(data).show();
            }
        })
        .fail(function (data) {
            $button.attr("disabled", false).removeClass("is-loading");
            $formMessages.removeClass("success").addClass("alert");

            // Set the message text.
            if (data.responseText && data.responseText !== "") {
                $formMessages.html(data.responseText).show();
            } else {
                $formMessages.html('Pri odosielaní sa vyskytol problém, skúste to znova.').show();
            }
        });

    return false;
});